interface StyledHeaderProps {
  label: string;
}

export const StyledHeader: React.FC<StyledHeaderProps> = ({
  label
}) => {
  return (
    <h2>
        {label}
    </h2>
  );
};
