import React from "react";
import { Box, Modal, Typography, IconButton, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";

const exportErrorAsTxt = (errorDetails: string) => {
  const blob = new Blob([errorDetails], { type: "text/plain" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = "error_details.txt";
  link.click();
};

interface ErrorModalProps {
  open: boolean;
  onClose: () => void;
  errorDetails: { message: string; code: string };
}

const ErrorModal: React.FC<ErrorModalProps> = ({
  open,
  onClose,
  errorDetails,
}) => {
  const errorText = `Message: ${errorDetails.message}\nCode: ${errorDetails.code}`;

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="error-modal-title"
      aria-describedby="error-modal-description"
    >
      <Box
        sx={{
          maxWidth: "40%",
          maxHeight: "70vh",
          margin: "auto",
          marginTop: "8%",
          backgroundColor: "#eef2f5",
          outline: "none",
          position: "relative",
          boxShadow: 24,
          display: "flex",
          flexDirection: "column",
          color: "#333",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            padding: "0px 6px",
            backgroundColor: "#e0e0e0",
          }}
        >
          <IconButton
            onClick={onClose}
            sx={{
              color: "#777",
              "&:hover": {
                color: "#f28b82",
              },
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            padding: 4,
            overflowY: "auto",
            maxHeight: "calc(70vh - 32px)",
            "&::-webkit-scrollbar": {
              width: "5px",
            },
            "&::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#888",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#555",
            },
          }}
        >
          <Typography
            variant="h6"
            id="error-modal-title"
            sx={{ textAlign: "center", marginBottom: 2 }}
          >
            <span style={{ color: "#d32f2f", fontWeight: "bold" }}>
              Error Details
            </span>
          </Typography>
          <Box sx={{ marginTop: 2 }}>
            <Typography variant="body1">
              <strong>Function:</strong> {"test"}
            </Typography>
            <Typography variant="body1" sx={{ marginTop: 1 }}>
              <strong>Message:</strong> {errorDetails.message}
            </Typography>
            <Typography variant="body1" sx={{ marginTop: 1 }}>
              <strong>Code:</strong> {errorDetails.code}
            </Typography>
          </Box>
          <Box sx={{ marginTop: 3, textAlign: "center" }}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<DownloadIcon />}
              onClick={() => exportErrorAsTxt(errorText)}
              sx={{ padding: "4px 8px", fontSize: "0.8rem" }}
            >
              Export as TXT
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ErrorModal;
