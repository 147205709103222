import { SyncDisabled } from "@mui/icons-material";
import { CssBaseline, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { theme } from "../../../styles/theme";
import logo from "../../../assets/logo.webp";
import React from "react";

export const ErrorPage = () => {
  return (
    <React.Fragment>
      <CssBaseline />
      <Box
        data-testid="error-component"
        role="alert"
        aria-live="assertive"
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: theme.background.page,
          color: theme.palette.secondary.contrastText,
          textAlign: "center",
          padding: 2,
        }}
      >
        <Box
          component="img"
          alt="ABS Logo"
          src={logo}
          sx={{
            width: "400px",
            height: "auto",
          }}
        />
        <Typography variant="h5" sx={{ margin: 3, color: "error.main" }}>
          An error occurred while loading the page. Please try again later.
        </Typography>
      </Box>
    </React.Fragment>
  );
};
