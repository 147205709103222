import { createBrowserRouter } from "react-router-dom";
import { Login } from "../routes/login/login.route";
import { Root } from "../routes/root/root.route";
import { HomeRoute } from "../routes/home/home.route";
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";
import { RestrictedRouter } from "./restricted.router";
import { ErrorPage } from "../shared/components/error/errorPage.component";
import { CartsRoute } from "../routes/carts/carts.route";

export interface RouteItem {
  path: string;
  breadcrumbName?: string;
}

export const ROUTES = {
  home: { path: "/", breadcrumbName: "Home" },
  login: { path: "/login", breadcrumbName: "" },
  carts: { path: "/carts", breadcrumbName: "Carts" },
};

const rootWithComponent = (
  component: EmotionJSX.Element
): EmotionJSX.Element => {
  return (
    <RestrictedRouter
      childComponent={<Root childComponent={component} />}
    ></RestrictedRouter>
  );
};

export const router = createBrowserRouter([
  {
    path: ROUTES.home.path,
    element: rootWithComponent(<HomeRoute />),
    errorElement: <ErrorPage />,
  },
  {
    path: ROUTES.login.path,
    element: <Login />,
    errorElement: <ErrorPage />,
  },
  {
    path: ROUTES.carts.path,
    element: rootWithComponent(<CartsRoute />),
    errorElement: <ErrorPage />,
  },
]);
