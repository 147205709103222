import Stack from "@mui/material/Stack";
import { useState, useEffect } from "react";

const Version = () => {
  const [version, setVersion] = useState("");

  useEffect(() => {
    fetch("/version.json")
      .then((response) => response.json())
      .then((data) => {
        setVersion(data.version);
      })
      .catch((error) => {
        console.error("Error fetching current application version:", error);
      });
  }, []);

  return (
    <Stack
      fontSize={12}
      sx={{ margin: "5px 5px 10px 5px" }}
      alignItems="center"
      justifyContent="center"
      direction="row"
      spacing={2}
    >
      <p>v{version}</p>
    </Stack>
  );
};

export default Version;
