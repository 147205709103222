import { Box } from "@mui/material";
import CartsView from "../../shared/components/cart/cartsView.component";
import { SnackbarProvider } from "../../shared/context/snackBarAlert.context";

export function CartsRoute() {
  return (
    <Box
      sx={{
        marginTop: "10px",
        flex: 1,
        overflowX: "hidden",
        "&::-webkit-scrollbar": {
          width: "5px",
          alignItems: "flex-end",
        },
        "&::-webkit-scrollbar-track": {
          background: "#f1f1f1",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#888",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          background: "#555",
        },
      }}
    >
      <CartsView />
    </Box>
  );
}
