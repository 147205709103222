import React from "react";
import { Box, Modal, Typography, Skeleton, IconButton } from "@mui/material";
import { SimpleTreeView, TreeItem } from "@mui/x-tree-view";
import { EquipmentMasterBase } from "../../../models/equipment/equipmentMasterBase.model";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";

interface TreeModalProps {
  open: boolean;
  onClose: () => void;
  data: EquipmentMasterBase[];
  loading: boolean;
}

const PartsTreeModal: React.FC<TreeModalProps> = ({
  open,
  onClose,
  data,
  loading,
}) => {
  const renderTreeItems = (node: EquipmentMasterBase, level = 0) => (
    <TreeItem
      key={node.equipmentId}
      itemId={String(node.equipmentId)}
      label={
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          paddingLeft={`${level * 16}px`}
          onClick={(e) => e.stopPropagation()}
          sx={{
            marginBottom: 1,
            marginTop: 1,
          }}
        >
          <Box flex="1">
            <Typography sx={{ color: "#000", marginLeft: 1 }}>
              {node.name}
            </Typography>
            <Typography
              sx={{
                color: "#777",
                fontSize: "0.7rem",
                marginLeft: 1,
              }}
            >
              {`${node.hcsAbbreviation} - [${node.equipCode}]`}
            </Typography>
          </Box>
        </Box>
      }
      sx={{
        "& .MuiTreeItem-iconContainer": {
          color: "#555",
          fontSize: "1.5rem",
        },
      }}
    >
      {node.children?.map((child) => renderTreeItems(child, level + 1))}
    </TreeItem>
  );

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          maxWidth: "40%",
          maxHeight: "70vh",
          margin: "auto",
          marginTop: "8%",
          backgroundColor: "#eef2f5",
          outline: "none",
          position: "relative",
          boxShadow: 24,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            padding: "0px 6px",
            backgroundColor: "#e0e0e0",
          }}
        >
          <IconButton
            onClick={onClose}
            sx={{
              color: "#777",
              "&:hover": {
                color: "#f28b82",
              },
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            padding: 4,
            overflowY: "auto",
            maxHeight: "calc(70vh - 32px)",
            "&::-webkit-scrollbar": {
              width: "5px",
            },
            "&::-webkit-scrollbar-track": {
              background: "#f1f1f1",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#888",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#555",
            },
          }}
        >
          {loading ? (
            <Box>
              {[...Array(2)].map((_, index) => (
                <Box key={index} sx={{ margin: 3, marginTop: 4 }}>
                  <Skeleton
                    variant="rectangular"
                    height={25}
                    width="60%"
                    sx={{ marginBottom: 1, backgroundColor: "#D3D3D3" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height={12}
                    width="25%"
                    sx={{ marginBottom: 4, backgroundColor: "#D3D3D3" }}
                  />
                </Box>
              ))}
            </Box>
          ) : data.length > 0 ? (
            <SimpleTreeView>
              {data.map((item) => renderTreeItems(item))}
            </SimpleTreeView>
          ) : (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              padding={2}
            >
              <Typography
                variant="body2"
                color="#333"
                display="flex"
                alignItems="center"
              >
                <InfoIcon sx={{ marginRight: 1, color: "#555" }} />
                No parts found for this equipment.
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default PartsTreeModal;
