import React from "react";
import { Button, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { grey } from "@mui/material/colors";

interface EditCartButtonProps {
  cartId: string;
  onEdit: () => void;
  onUpdateSuccess: () => void;
  onUpdateFailed: () => void;
}

export const EditCartButton: React.FC<EditCartButtonProps> = ({
  cartId,
  onEdit,
  onUpdateSuccess,
  onUpdateFailed,
}) => {
  const buttonSx = {
    paddingY: "4px",
    paddingX: "8px",
    minWidth: "auto",
    bgcolor: grey[500],
    "&:hover": {
      bgcolor: grey[700],
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const handleEdit = () => {
    onEdit();
  };

  return (
    <Tooltip title="Edit" arrow placement="left">
      <span>
        <Button
          size="small"
          color="error"
          variant="contained"
          onClick={handleEdit}
          sx={buttonSx}
        >
          <EditIcon fontSize="inherit" />
        </Button>
      </span>
    </Tooltip>
  );
};
