import { IconButton } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";

export const SignInButton = () => {
	const defaultFontSize = 16;
	const { instance } = useMsal();
	const { t } = useTranslation();

	const handleLogin = () => {
		instance.loginRedirect();
	};

	return (
		<IconButton
			size="large"
			aria-label="account of current user"
			aria-controls="menu-appbar"
			aria-haspopup="true"
			onClick={() => handleLogin()}
			color="inherit"
		>
			<Typography
				variant="subtitle2"
				component="div"
				noWrap={true}
				sx={{ m: 1, fontSize: defaultFontSize, fontWeight: 400 }}
			>
				{t("routes.login.components.sign_in")}
			</Typography>
		</IconButton>
	);
};
