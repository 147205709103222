import { styled } from "@mui/material/styles";
import { TreeItem } from "@mui/x-tree-view";

const StyledTreeItem = styled(TreeItem)(({ theme }) => ({
  ".MuiTreeItem-content.Mui-selected, .Mui-selected": {
    backgroundColor: "transparent !important",
  },
  ".MuiTreeItem-content.Mui-selected:hover, .Mui-selected:hover": {
    backgroundColor: "transparent !important",
  },
}));

export default StyledTreeItem;
