import axios from "axios";
import { IDownloadExport } from "../shared/models/cart/actions/exportCart.model";
import { API_KEY, API_URL } from "../shared/constants/cartsConstants";
import { IContext } from "../shared/models/context/user.context.model";
import { ErrorDetails } from "../shared/models/error/errorDetails.model";

interface IDownloadExportRoot {
  data?: IDownloadExport[];
  error?: ErrorDetails;
}

const createConfig = () => ({
  headers: {
    "x-functions-key": API_KEY,
  },
});

const handleError = (error: any) => {
  if (axios.isAxiosError(error) && error.response?.data?.error) {
    const { message, code } = error.response.data.error as ErrorDetails;
    throw { message, code };
  }
};

export const GetAllDownloadsAsync = async (
  context: IContext
): Promise<IDownloadExport[]> => {
  try {
    const { homeAccountId } = context.user;
    const response = await axios.get<IDownloadExportRoot>(
      `${API_URL}/${homeAccountId}/downloads`,
      createConfig()
    );

    if (response.data?.error) {
      handleError(response.data.error);
    }

    return response.data?.data ?? [];
  } catch (error) {
    handleError(error);
  }
  return [];
};
