import { Box } from "@mui/material";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useState } from "react";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const UserGraphView: React.FC = () => {
  const [interval, setInterval] = useState("1M");

  const generateRealisticData = (
    length: number,
    baseValue: number,
    maxVariation: number
  ) => {
    let currentValue = baseValue;
    return Array.from({ length }, () => {
      const variation = Math.random() * maxVariation - maxVariation / 2;
      currentValue = Math.max(0, currentValue + variation);
      return Math.round(currentValue);
    });
  };

  const generateData = () => {
    const labels =
      interval === "1M"
        ? ["Week 1", "Week 2", "Week 3", "Week 4"]
        : ["Month 1", "Month 2", "Month 3"];
    const length = labels.length;
    return {
      labels,
      datasets: [
        {
          label: "Carts Added",
          data: generateRealisticData(
            length,
            interval === "1M" ? 20 : 60,
            interval === "1M" ? 10 : 20
          ),
          borderColor: "rgba(75,192,192,1)",
          backgroundColor: "rgba(75,192,192,0.2)",
          borderWidth: 2,
          fill: true,
        },
        {
          label: "Carts Exported",
          data: generateRealisticData(
            length,
            interval === "1M" ? 15 : 50,
            interval === "1M" ? 8 : 15
          ),
          borderColor: "rgba(153,102,255,1)",
          backgroundColor: "rgba(153,102,255,0.2)",
          borderWidth: 2,
          fill: true,
        },
        {
          label: "Equipments",
          data: generateRealisticData(
            length,
            interval === "1M" ? 30 : 80,
            interval === "1M" ? 12 : 25
          ),
          borderColor: "rgba(75,192,75,1)",
          backgroundColor: "rgba(75,192,75,0.2)",
          borderWidth: 2,
          fill: true,
        },
      ],
    };
  };

  const data = generateData();

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: { color: "#bbb" },
      },
      x: {
        ticks: { color: "#bbb" },
      },
    },
    plugins: {
      legend: {
        display: true,
        labels: {
          color: "#bbb",
        },
      },
    },
  };
  return (
    <Box sx={{ width: "100%", height: "50%" }}>
      <Line data={data} options={options} />
    </Box>
  );
};

export default UserGraphView;
