import React, { useEffect, useState } from "react";
import { Box, Pagination } from "@mui/material";
import { detectBrowser } from "../../../utils/browser/DetectBrowser";
import { EquipmentMasterBase } from "../../models/equipment/equipmentMasterBase.model";
import { GetPagedEquipments } from "../../../service/equipmentService";
import EquipmentsTreeBox from "./equipmentsTreeBox.component";
import { SortingOption } from "../../../utils/filters/FilterSettings";
import SearchEquipments from "./searchEquipment.component";
import { useSnackbarContext } from "../../context/snackBarAlert.context";
import { ErrorDetails } from "../../models/error/errorDetails.model";

const ListEquipmentsSidePanel: React.FC = () => {
  const { openSnackbar } = useSnackbarContext();
  const [browser, setBrowser] = useState<string>("");
  const [totalItems, setTotalItems] = useState<number>(0);
  const [equipmentList, setEquipmentList] = useState<EquipmentMasterBase[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [debouncedQuery, setDebouncedQuery] = useState<string>("");
  const [sortState, setSortState] = useState<SortingOption>(SortingOption.ASC);
  const [totalPages, setTotalPages] = useState<number>(0);

  const fetchEquipments = async (page = pageNumber, query = searchQuery) => {
    setLoading(true);
    try {
      const data = await GetPagedEquipments(page, query);
      setTotalItems(data.totalItems);
      setEquipmentList(data.data);
      setTotalPages(data.totalPages);
    } catch (error) {
      openSnackbar(
        "An unexpected error occurred",
        "error",
        error as ErrorDetails
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(searchQuery);
    }, 500);
    return () => clearTimeout(handler);
  }, [searchQuery]);

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    setPageNumber(1);
    if (query.length >= 3) {
      setDebouncedQuery(query);
    }
  };

  useEffect(() => {
    if (debouncedQuery.length >= 3 || debouncedQuery === "") {
      fetchEquipments();
    }
    setBrowser(detectBrowser());
  }, [pageNumber, debouncedQuery]);

  const handleSort = () => {
    setSortState((prev) =>
      prev === SortingOption.ASC ? SortingOption.DESC : SortingOption.ASC
    );
  };

  const sortedEquipments = equipmentList.sort((a, b) => {
    const comparison = a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    return sortState === SortingOption.ASC ? comparison : -comparison;
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "86vh",
        margin: 0,
      }}
    >
      <SearchEquipments
        onSearch={handleSearch}
        onSortOrder={handleSort}
        SearchEquipmentByName={(name: string) => fetchEquipments(1, name)}
      />
      <Box
        sx={{
          overflowY: "auto",
          marginTop: "10px",
          flex: 1,
          "&::-webkit-scrollbar": { width: "5px" },
          "&::-webkit-scrollbar-track": { background: "#f1f1f1" },
          "&::-webkit-scrollbar-thumb": { background: "#888" },
          "&::-webkit-scrollbar-thumb:hover": { background: "#555" },
        }}
      >
        <EquipmentsTreeBox equipments={sortedEquipments} loading={isLoading} />
      </Box>
      <Box
        sx={{
          marginTop: "10px",
          alignSelf: "center",
          fontSize: "12px",
          color: "#333",
          padding: "2px 8px",
          backgroundColor: "#e0e0e0",
          display: "inline-block",
        }}
      >
        Total: <b>{totalItems}</b>
      </Box>
      <Pagination
        count={totalPages}
        page={pageNumber}
        onChange={(_, value) => setPageNumber(value)}
        color="primary"
        sx={{ marginTop: "10px", alignSelf: "center" }}
      />
    </Box>
  );
};

export default ListEquipmentsSidePanel;
