export enum BrowserType {
  CHROME = "chrome",
  FIREFOX = "firefox",
  SAFARI = "safari",
  EDGE = "edge",
  OTHER = "other",
}

export const detectBrowser = () => {
  if (typeof navigator === "undefined" || !navigator.userAgent) {
    return BrowserType.OTHER;
  }

  const userAgent = navigator.userAgent;
  const CHROME_IDENTIFIER = "Chrome";
  const EDGE_IDENTIFIER = "Edg";
  const FIREFOX_IDENTIFIER = "Firefox";
  const SAFARI_IDENTIFIER = "Safari";

  if (
    userAgent.indexOf(CHROME_IDENTIFIER) > -1 &&
    userAgent.indexOf(EDGE_IDENTIFIER) === -1
  ) {
    return BrowserType.CHROME;
  } else if (userAgent.indexOf(FIREFOX_IDENTIFIER) > -1) {
    return BrowserType.FIREFOX;
  } else if (
    userAgent.indexOf(SAFARI_IDENTIFIER) > -1 &&
    userAgent.indexOf(CHROME_IDENTIFIER) === -1
  ) {
    return BrowserType.SAFARI;
  } else if (userAgent.indexOf(EDGE_IDENTIFIER) > -1) {
    return BrowserType.EDGE;
  } else {
    return BrowserType.OTHER;
  }
};
