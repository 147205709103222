import axios from "axios";
import { API_KEY, API_URL } from "../shared/constants/cartsConstants";
import { IContext } from "../shared/models/context/user.context.model";
import { ErrorDetails } from "../shared/models/error/errorDetails.model";

const createConfig = () => ({
  headers: {
    "x-functions-key": API_KEY,
  },
});

const handleError = (error: any) => {
  if (axios.isAxiosError(error) && error.response?.data?.error) {
    const { message, code } = error.response.data.error as ErrorDetails;
    throw { message, code };
  }
};

export const ExportAsync = async (
  context: IContext,
  cartId: string
): Promise<void> => {
  try {
    const data = {
      cartId,
      userId: context.user.homeAccountId,
    };

    const response = await axios.post(
      `${API_URL}/export`,
      data,
      createConfig()
    );

    if (response.status !== 200) {
      handleError({
        functionName: "ExportAsync",
        message: response.statusText,
        code: "EXPORT_ERROR",
      });
    }
  } catch (error) {
    handleError(error);
  }
};
