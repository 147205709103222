import { TextField, styled } from "@mui/material";
import { theme } from '../../../../styles/theme';

const borderColor = "#E8ECED";
const disabledTextColor = theme.palette.text.primary;
const inputFontSize = theme.typography.body1.fontSize;
const textColor = theme.palette.text.primary;

const TextFieldCustom = styled(TextField)({
	"& .MuiInputLabel-root": {
		color: textColor,
		transform: "translate(14px, -20px) scale(1)",
	},
	"& .MuiInputBase-root": {
		marginRight: "6px",
		borderColor: textColor,
	},
	"& .MuiInputBase-root.Mui-disabled": {
		backgroundColor: "transparent",
		"& fieldset": {
			borderColor: textColor,
		},
	},
	"& .MuiOutlinedInput-root": {
		'& fieldset': {
		},
		"&.Mui-focused fieldset": {
			borderColor: textColor,
		},
		color: textColor,
		backgroundColor: 'transparent',
		borderColor: textColor,
	},
	"& .MuiInputBase-input": {
		color: textColor,
		borderColor: textColor
	},
	"& .MuiInputBase-input.Mui-disabled": {
		WebkitTextFillColor: disabledTextColor,
	},
	"& label.Mui-focused": {
		color: textColor,
		borderColor: borderColor,
	},
});

export const StyledTextFieldTransparent = ({
	...props
}) => {
	return (
		<TextFieldCustom
			margin="normal"
			variant="outlined"
			inputProps={{ style: { fontSize: inputFontSize, padding: 8 } }}
			InputLabelProps={{
				shrink: false,
				style: { fontSize: 0, left: "-8px" },
			}}
			{...props}
		/>
	);
};
