import axios from "axios";
import { EquipmentMasterBase } from "../shared/models/equipment/equipmentMasterBase.model";
import { API_KEY, API_URL } from "../shared/constants/equipmentsConstants";
import { IEquipmentPaged } from "../shared/models/equipment/pagedEquipment.model";
import { ErrorDetails } from "../shared/models/error/errorDetails.model";

const createConfig = () => ({
  headers: {
    "x-functions-key": API_KEY,
  },
});

const handleError = (error: any) => {
  if (axios.isAxiosError(error) && error.response?.data?.error) {
    const { message, code } = error.response.data.error as ErrorDetails;
    throw { message, code };
  }
};

export const GetEquipmentsByCartId = async (
  cartId: string
): Promise<EquipmentMasterBase[]> => {
  try {
    const response = await axios.get(
      `${API_URL}/by-cart/${cartId}`,
      createConfig()
    );
    return Array.isArray(response.data.data) ? response.data.data : [];
  } catch (error) {
    handleError(error);
    return [];
  }
};

export const GetEquipmentPartsById = async (
  id: number
): Promise<EquipmentMasterBase[]> => {
  try {
    const response = await axios.get(`${API_URL}/${id}/parts`, createConfig());
    return Array.isArray(response.data.data) ? response.data.data : [];
  } catch (error) {
    handleError(error);
    return [];
  }
};

export const GetEquipmentHierarchy = async (
  id: number,
  includeParts: boolean
): Promise<EquipmentMasterBase[]> => {
  try {
    const response = await axios.get(
      `${API_URL}/${id}/hierarchy?includeParts=${includeParts}`,
      createConfig()
    );
    return Array.isArray(response.data.data) ? response.data.data : [];
  } catch (error) {
    handleError(error);
    return [];
  }
};

export const GetEquipmentChildren = async (
  id: number
): Promise<EquipmentMasterBase[]> => {
  try {
    const response = await axios.get(
      `${API_URL}/${id}/children`,
      createConfig()
    );
    return Array.isArray(response.data.data) ? response.data.data : [];
  } catch (error) {
    handleError(error);
    return [];
  }
};

export const GetPagedEquipments = async (
  page: number,
  search?: string
): Promise<IEquipmentPaged> => {
  try {
    const params = new URLSearchParams({
      page: page.toString(),
      pageSize: "9",
    });

    if (search) {
      params.append("search", search);
    }

    const response = await axios.get(
      `${API_URL}?${params.toString()}`,
      createConfig()
    );

    return (
      response.data ||
      ({
        data: [],
        currentPage: page,
        totalPages: 0,
        perPage: 0,
        totalItems: 0,
        statusCode: 0,
      } as IEquipmentPaged)
    );
  } catch (error) {
    handleError(error);
    return {
      data: [],
      currentPage: page,
      totalPages: 0,
      perPage: 0,
      totalItems: 0,
      statusCode: 0,
    };
  }
};
