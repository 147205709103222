import { Configuration, LogLevel } from "@azure/msal-browser";

const clientId = process.env.REACT_APP_MSAL_CONFIG_CLIENT_ID || "";
const authority = process.env.REACT_APP_MSAL_CONFIG_DEFAULT_AUTHORITY || "";
const redirectUri = process.env.REACT_APP_REDIRECT_URI || "";

export const msalConfig: Configuration = {
  auth: {
    clientId,
    authority,
    redirectUri,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    allowNativeBroker: false,
    loggerOptions: {
      loggerCallback: (
        level: LogLevel,
        message: string,
        containsPii: boolean
      ) => {
        if (containsPii) return;

        const logMethods: Partial<Record<LogLevel, (msg: string) => void>> = {
          [LogLevel.Error]: console.error,
          [LogLevel.Info]: console.info,
          [LogLevel.Verbose]: console.debug,
          [LogLevel.Warning]: console.warn,
          [LogLevel.Trace]: console.trace,
        };

        logMethods[level]?.(message) ||
          console.log(`Unhandled log level: ${message}`);
      },
    },
  },
};

export const loginRequest = {
  scopes: ["user.read"],
};
