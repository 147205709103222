import React, { useState } from "react";
import { TreeItem } from "@mui/x-tree-view";
import { Box, Checkbox, Typography, IconButton, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { EquipmentMasterBase } from "../../../models/equipment/equipmentMasterBase.model";
import PartsTreeModal from "../modals/partsTreeModal.component";
import { GetEquipmentPartsById } from "../../../../service/equipmentService";

interface EquipmentTreeItemProps {
  node: EquipmentMasterBase;
  level?: number;
  selected: number[];
  toggleSelectEquipment: (id: number, children: EquipmentMasterBase[]) => void;
  handleItemClick: (
    event: React.MouseEvent<Element, MouseEvent>,
    id: number
  ) => void;
}

const EquipmentTreeItem: React.FC<EquipmentTreeItemProps> = ({
  node,
  level = 0,
  selected,
  toggleSelectEquipment,
  handleItemClick,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoadingParts, setIsLoadingParts] = useState(false);
  const [modalData, setModalData] = useState<EquipmentMasterBase[]>([]);

  const handleIconClick = async (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsLoadingParts(true);
    setModalOpen(true);

    try {
      const partsData = await GetEquipmentPartsById(node.equipmentId);
      setModalData(partsData || []);
    } catch (error) {
      console.error("Error fetching equipment parts:", error);
      setModalData([]);
    } finally {
      setIsLoadingParts(false);
    }
  };

  return (
    <>
      <TreeItem
        key={node.equipmentId}
        itemId={String(node.equipmentId)}
        label={
          <Box
            display="flex"
            flexDirection="column"
            paddingLeft={`${level * 16}px`}
            onClick={(e) => e.stopPropagation()}
          >
            <Box display="flex" alignItems="center">
              <Checkbox
                size="small"
                sx={{
                  color: "#555",
                  "&.Mui-checked": {
                    color: "#1976d2",
                  },
                  "&.MuiCheckbox-root:hover": {
                    backgroundColor: "rgba(21, 101, 192, 0.08)",
                    color: "#1976d2",
                  },
                  "&.MuiCheckbox-root.Mui-focusVisible": {
                    outline: "2px solid rgba(25, 118, 210, 0.4)",
                  },
                  transition: "all 0.3s ease-in-out",
                  marginTop: 1,
                }}
                checked={selected.includes(node.equipmentId)}
                onChange={() =>
                  toggleSelectEquipment(node.equipmentId, node.children || [])
                }
              />
              <Typography sx={{ color: "#000", marginRight: 1, marginTop: 1 }}>
                {node.name}
              </Typography>
              {!node.children?.length && (
                <IconButton
                  size="small"
                  onClick={handleIconClick}
                  sx={{
                    marginLeft: 1,
                    marginTop: 1,
                    color: "#777",
                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.1)",
                    },
                  }}
                >
                  <Tooltip
                    title="View parts"
                    placement="left"
                    arrow
                    slotProps={{
                      tooltip: {
                        sx: {
                          backgroundColor: "#555",
                          color: "#fff",
                          fontSize: "0.875rem",
                          padding: "8px 12px",
                        },
                      },
                      arrow: {
                        sx: {
                          color: "#555",
                        },
                      },
                    }}
                  >
                    <InfoIcon />
                  </Tooltip>
                </IconButton>
              )}
            </Box>
            <Typography
              sx={{
                color: "#777",
                fontSize: "0.7rem",
                marginLeft: "40px",
                marginBottom: "10px",
              }}
            >
              {`${node.hcsAbbreviation} - [${node.equipCode}]`}
            </Typography>
          </Box>
        }
        onClick={(e) => handleItemClick(e, node.equipmentId)}
        sx={{
          "& .MuiTreeItem-iconContainer": {
            color: "#555",
            pointerEvents: "auto",
            cursor: "pointer",
          },
        }}
      >
        {node.children &&
          node.children.map((child) => (
            <EquipmentTreeItem
              key={child.equipmentId}
              node={child}
              level={level + 1}
              selected={selected}
              toggleSelectEquipment={toggleSelectEquipment}
              handleItemClick={handleItemClick}
            />
          ))}
      </TreeItem>
      <PartsTreeModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        data={modalData}
        loading={isLoadingParts}
      />
    </>
  );
};

export default EquipmentTreeItem;
