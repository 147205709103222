import { Box, styled } from "@mui/material";
import { theme } from "../../../../styles/theme";

const borderColor = "#000000";
const backgroundColor = theme.background.componentLight;

export const StyledBox = styled(Box)(() => {
  return {
    backgroundColor: backgroundColor,
    borderColor: borderColor,
    borderWidth: "1px",
    display: "inline-block",
    padding: "12px 6px 10px 6px",
  };
});
