import React from "react";
import { AppBar, Tabs, Tab, Box } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { theme } from "../../styles/theme";
import { useTranslation } from "react-i18next";

const NavigationComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    navigate(newValue);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          backgroundColor: theme.background.page,
        }}
      >
        <AppBar
          position="static"
          sx={{ backgroundColor: theme.background.componentLight }}
        >
          <Tabs
            value={location.pathname}
            onChange={handleChange}
            sx={{ color: theme.palette.primary.light }}
          >
            <Tab label={t("navigation.home")} value="/" />
            <Tab label={t("navigation.carts")} value="/carts" />
          </Tabs>
        </AppBar>
      </Box>
    </>
  );
};

export default NavigationComponent;
