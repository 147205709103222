import { theme } from "../../../styles/theme";
import Box from "@mui/material/Box";
import { Stack } from "@mui/material";
import ListEquipmentsSidePanel from "../equipment/listEquipmentsSidePanel.component";

export const LeftPanel = () => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "28%",
          padding: 1,
          backgroundColor: theme.background.componentLight,
        }}
      >
        <Stack direction="column" spacing={2}>
          <ListEquipmentsSidePanel />
        </Stack>
      </Box>
    </>
  );
};
