import React, { useState } from "react";
import {
  AppBar,
  Box,
  Stack,
  ThemeProvider,
  Toolbar,
  Typography,
  Popover,
  Tooltip,
} from "@mui/material";
import { theme } from "../../../styles/theme";
import { SignOutButton } from "../../../routes/login/components/SignOutButton";
import { useAppContext } from "../../context/app.context";
import { useTranslation } from "react-i18next";
import logo from "../../../assets/logo.webp";
import NotificationBell from "./notification.component";
import Version from "./version.component";

const Header = () => {
  const appContext = useAppContext();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleNameClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <ThemeProvider theme={theme}>
      <AppBar
        position="static"
        sx={{
          width: "100%",
          marginBottom: "8px",
          padding: "10px 10px 0px 10px",
          height: "80px",
          backgroundColor: theme.background.componentLight,
          color: theme.palette.secondary.contrastText,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Toolbar
          disableGutters
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "50%",
          }}
        >
          <Stack
            alignItems="center"
            justifyContent="left"
            direction="row"
            sx={{
              mb: 1,
              width: "35%",
              padding: 1,
            }}
          >
            <Box
              sx={{
                margin: "5px",
                width: "350px",
                height: "auto",
              }}
              component="img"
              alt={t("component.leftpanel.abs_Logo")}
              src={logo}
              loading="lazy"
            />
            <Typography
              variant="caption"
              sx={{
                mt: 4,
                color: theme.palette.text.secondary,
                paddingLeft: 1,
              }}
            >
              <Version />
            </Typography>
          </Stack>

          <Stack
            justifyContent="flex-start"
            direction="row"
            sx={{
              mb: 0.5,
              width: "20%",
              padding: 1,
            }}
          ></Stack>
        </Toolbar>
        <Toolbar
          disableGutters
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            width: "50%",
          }}
        >
          <Stack
            alignItems="center"
            justifyContent="flex-end"
            direction="row"
            spacing={2}
            paddingRight={2}
          >
            <NotificationBell notifications={[]} />
            <Typography
              variant="menuItem"
              component="div"
              noWrap={true}
              onClick={handleNameClick}
              sx={{ cursor: "pointer" }}
            >
              {appContext.user.name}
            </Typography>
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Box sx={{ p: 2 }}>
                <Tooltip title="Logout" placement="left">
                  <span>
                    {" "}
                    <SignOutButton />
                  </span>
                </Tooltip>
              </Box>
            </Popover>
          </Stack>
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  );
};

export default Header;
