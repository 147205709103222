import React, { useState } from "react";
import {
  Box,
  Stack,
  Typography,
  IconButton,
  Popover,
  Paper,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import { Cart } from "../../../models/cart/cart.model";
import { DeleteCartButton } from "./buttons/deleteCartButton.component";
import { ExportCartButton } from "./buttons/exportCartButton.component";
import { EditCartButton } from "./buttons/editCartButton.component";
import { ErrorDetails } from "../../../models/error/errorDetails.model";

interface CartDetailsProps {
  cart: Cart;
  onDeleteSuccess: () => void;
  onDeleteError: (error: ErrorDetails) => void;
  onUpdateSuccess: () => void;
  onUpdateFailed: () => void;
  onEdit: () => void;
}

const formatDate = (date: string) => {
  return new Intl.DateTimeFormat(navigator.language, {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  }).format(new Date(date));
};

const CartPopupActions: React.FC<CartDetailsProps> = ({
  cart,
  onDeleteSuccess,
  onDeleteError,
  onUpdateSuccess,
  onUpdateFailed,
  onEdit,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isExportDisabled = cart.totalEquipments === 0;

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "cart-actions-popover" : undefined;

  return (
    <Box sx={{ position: "relative", width: "100%" }}>
      <IconButton
        onClick={handleOpen}
        sx={{ position: "absolute", top: 0, right: 0 }}
      >
        <SettingsIcon />
      </IconButton>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Box sx={{ flex: 3, padding: 1, paddingBottom: 0 }}>
          <Typography paddingBottom={2} variant="h6">
            <b>{cart.name}</b>
          </Typography>
          <Typography variant="body2" sx={{ fontSize: "12px" }}>
            Total equipments: <b>{cart.totalEquipments}</b>
          </Typography>
          <Typography variant="body2" sx={{ fontSize: "12px" }}>
            {cart.updatedAt ? "Updated at:" : "Created at:"}{" "}
            <b>{formatDate(cart.updatedAt || cart.createdAt)}</b>
          </Typography>
        </Box>
      </Stack>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Paper sx={{ padding: 1 }}>
          <Stack spacing={1}>
            <DeleteCartButton
              onDeleteSuccess={onDeleteSuccess}
              cartId={cart.id}
              onDeleteError={onDeleteError}
            />
            <EditCartButton
              onEdit={onEdit}
              onUpdateSuccess={onUpdateSuccess}
              onUpdateFailed={onUpdateFailed}
              cartId={cart.id}
            />
            <ExportCartButton cartId={cart.id} disabled={isExportDisabled} />
          </Stack>
        </Paper>
      </Popover>
    </Box>
  );
};

export default CartPopupActions;
