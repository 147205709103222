import { Box, ThemeProvider } from "@mui/material";
import { theme } from "../../../styles/theme";
import { useEffect, useState } from "react";
import { VerifyIfUserExists } from "../../../service/userService";
import { useAppContext } from "../../context/app.context";
import UserGraphView from "./graphs/userGraphView.component";
import CartsGraphView from "./graphs/cartsGraphView.component";
import { StyledBox } from "../styled/box/styledBox.component";

const HomeView: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [noDataFound, setNoDataFound] = useState(false);
  const context = useAppContext();

  const fetchDownloads = async () => {
    setLoading(true);
    try {
      setNoDataFound(true);
    } catch (error) {
      console.error("Error fetching downloads data:", error);
      setNoDataFound(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    VerifyIfUserExists(context);
    fetchDownloads();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <StyledBox
        sx={{
          display: "flex",
          flexDirection: "row",
          height: "40vh",
          width: "98%",
          paddingY: 2,
          marginBottom: 2,
        }}
      >
        <Box sx={{ height: "70vh", width: "35vw", paddingRight: 1 }}>
          <CartsGraphView />
        </Box>
        <Box sx={{ height: "70vh", width: "32vw" }}>
          <UserGraphView />
        </Box>
      </StyledBox>

      <StyledBox
        sx={{
          display: "flex",
          flexDirection: "row",
          height: "10vh",
          width: "98%",
          paddingY: 2,
          marginBottom: 2,
        }}
      ></StyledBox>
      <StyledBox
        sx={{
          display: "flex",
          flexDirection: "row",
          height: "30vh",
          width: "98%",
          paddingY: 2,
          marginBottom: 2,
        }}
      ></StyledBox>
    </ThemeProvider>
  );
};

export default HomeView;
