import React, { createContext, useContext, useState, ReactNode } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";
import ErrorModal from "../components/error/errorModal.component";
import { ErrorDetails } from "../models/error/errorDetails.model";

interface SnackBarAlert {
  openSnackbar: (
    message: string,
    severity: "success" | "error" | "info",
    errorDetail?: ErrorDetails
  ) => void;
  closeSnackbar: () => void;
}

const SnackbarContext = createContext<SnackBarAlert | undefined>(undefined);
const CustomAlert = styled(Alert)(() => ({
  color: "white",
}));

export const SnackbarProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [snackOpen, setSnackOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState<"success" | "error" | "info">();
  const [errorDetails, setErrorDetails] = useState<ErrorDetails>();

  const openSnackbar = (
    message: string,
    severity: "success" | "error" | "info",
    errorDetail?: ErrorDetails
  ) => {
    setMessage(message);
    setSeverity(severity);
    setSnackOpen(true);

    if (severity === "error") {
      setErrorDetails(errorDetail);
    }
  };

  const closeSnackbar = () => {
    setSnackOpen(false);
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <SnackbarContext.Provider value={{ openSnackbar, closeSnackbar }}>
      {children}
      <Snackbar
        open={snackOpen}
        autoHideDuration={severity !== "error" ? 4000 : null}
        onClose={severity !== "error" ? closeSnackbar : undefined}
      >
        <CustomAlert
          onClose={closeSnackbar}
          severity={severity}
          variant="filled"
          sx={{
            width: "100%",
          }}
          action={
            severity === "error" ? (
              <Button
                color="inherit"
                size="small"
                onClick={() => {
                  openModal();
                  closeSnackbar();
                }}
                sx={{
                  fontSize: "0.7rem",
                  padding: "4px 8px",
                  border: "1px solid #d32f2f",
                  backgroundColor: "#f8d7da",
                  color: "#d32f2f",
                  borderRadius: "4px",
                  "&:hover": {
                    backgroundColor: "#f5b7b9",
                  },
                }}
              >
                Show Error
              </Button>
            ) : null
          }
        >
          {message}
        </CustomAlert>
      </Snackbar>

      {errorDetails && (
        <ErrorModal
          open={modalOpen}
          onClose={closeModal}
          errorDetails={{
            message: errorDetails.message,
            code: errorDetails.code,
          }}
        />
      )}
    </SnackbarContext.Provider>
  );
};

export const useSnackbarContext = () => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error(
      "useSnackbarContext must be used within a SnackbarProvider"
    );
  }
  return context;
};
