import React, { useState } from "react";
import { Box, Typography, Skeleton, IconButton, Tooltip } from "@mui/material";
import { SimpleTreeView, TreeItem } from "@mui/x-tree-view";
import { EquipmentMasterBase } from "../../../models/equipment/equipmentMasterBase.model";
import InfoIcon from "@mui/icons-material/Info";
import PartsTreeModal from "../modals/partsTreeModal.component";
import { GetEquipmentPartsById } from "../../../../service/equipmentService";

interface EquipmentSimpleTreeViewProps {
  data: EquipmentMasterBase[];
  loading: boolean;
}

const EquipmentSimpleTreeView: React.FC<EquipmentSimpleTreeViewProps> = ({
  data,
  loading,
}) => {
  const [expanded, setExpanded] = useState<string[]>([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoadingParts, setIsLoadingParts] = useState(false);
  const [modalData, setModalData] = useState<EquipmentMasterBase[]>([]);

  const toggleExpand = (itemId: string) => {
    setExpanded((prev) =>
      prev.includes(itemId)
        ? prev.filter((id) => id !== itemId)
        : [...prev, itemId]
    );
  };

  const handleIconClick = async (e: React.MouseEvent, equipmentId: number) => {
    e.stopPropagation();
    setIsLoadingParts(true);
    setModalOpen(true);

    try {
      const partsData = await GetEquipmentPartsById(equipmentId);
      setModalData(partsData || []);
    } catch (error) {
      console.error("Error fetching equipment parts:", error);
      setModalData([]);
    } finally {
      setIsLoadingParts(false);
    }
  };

  const renderTreeItems = (node: EquipmentMasterBase, level = 0) => (
    <TreeItem
      key={node.equipmentId}
      itemId={String(node.equipmentId)}
      label={
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          paddingLeft={`${level * 16}px`}
          onClick={(e) => e.stopPropagation()}
          sx={{
            marginBottom: 1,
            marginTop: 1,
          }}
        >
          <Box>
            <Typography sx={{ color: "#000", marginLeft: 1 }}>
              {node.name}
            </Typography>
            <Typography
              sx={{
                color: "#777",
                fontSize: "0.7rem",
                marginLeft: 1,
              }}
            >
              {`${node.hcsAbbreviation} - [${node.equipCode}]`}
            </Typography>
          </Box>
          {!node.children?.length && (
            <IconButton
              size="small"
              onClick={(e) => handleIconClick(e, node.equipmentId)}
              sx={{
                marginLeft: 2,
                marginTop: "-16px",
                color: "#777",
                backgroundColor: "rgba(0, 0, 0, 0.04)",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.1)",
                },
              }}
            >
              <Tooltip
                title="View parts"
                placement="left"
                arrow
                slotProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: "#555",
                      color: "#fff",
                      fontSize: "0.875rem",
                      padding: "8px 12px",
                    },
                  },
                  arrow: {
                    sx: {
                      color: "#555",
                    },
                  },
                }}
              >
                <InfoIcon />
              </Tooltip>
            </IconButton>
          )}
        </Box>
      }
      onClick={() => toggleExpand(String(node.equipmentId))}
      sx={{
        "& .MuiTreeItem-iconContainer": {
          color: "#555",
          fontSize: "1.5rem",
        },
      }}
    >
      {node.children?.map((child) => renderTreeItems(child, level + 1))}
    </TreeItem>
  );

  return (
    <Box sx={{ backgroundColor: "#eef2f5", padding: 2 }}>
      {loading ? (
        <Box>
          {[...Array(2)].map((_, index) => (
            <Box key={index} sx={{ margin: 3, marginTop: 4 }}>
              <Skeleton
                variant="rectangular"
                height={25}
                width="30%"
                sx={{ marginBottom: 1, backgroundColor: "#D3D3D3" }}
              />
              <Skeleton
                variant="rectangular"
                height={12}
                width="15%"
                sx={{ marginBottom: 4, backgroundColor: "#D3D3D3" }}
              />
            </Box>
          ))}
        </Box>
      ) : data.length > 0 ? (
        <SimpleTreeView expandedItems={expanded} sx={{ margin: 1 }}>
          {data.map((item) => renderTreeItems(item))}
        </SimpleTreeView>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding={2}
        >
          <Typography
            variant="body2"
            color="#333"
            display="flex"
            alignItems="center"
          >
            <InfoIcon sx={{ marginRight: 1, color: "#555" }} />
            No equipment found.
          </Typography>
        </Box>
      )}
      <PartsTreeModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        data={modalData}
        loading={isLoadingParts}
      />
    </Box>
  );
};

export default EquipmentSimpleTreeView;
