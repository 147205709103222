import { EmotionJSX } from "@emotion/react/types/jsx-namespace";
import { Box, Container, CssBaseline } from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import Header from "../../shared/components/header/header.component";
import { theme } from "../../styles/theme";
import { LeftPanel } from "../../shared/components/left-panel/leftPanel.component";
import NavigationComponent from "../../shared/navigation/navigation.component";
import { useIsAuthenticated } from "@azure/msal-react";
import { Login } from "../login/login.route";
import { detectBrowser } from "../../utils/browser/DetectBrowser";
import { EquipmentProvider } from "../../shared/context/equipment.context";
import { SnackbarProvider } from "../../shared/context/snackBarAlert.context";

interface RootProps {
  childComponent: ReactElement;
}

export function Root({ childComponent }: RootProps): EmotionJSX.Element {
  const isAuthenticated = useIsAuthenticated();
  const [browser, setBrowser] = useState<string>("");

  useEffect(() => {
    setBrowser(detectBrowser());
  }, []);

  if (isAuthenticated) {
    return (
      <SnackbarProvider>
        <EquipmentProvider>
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              backgroundColor: theme.background.page,
            }}
          >
            <Box sx={{ maxwidth: "20%" }}>
              <CssBaseline />
              <Header />
            </Box>
            <Box
              component="main"
              sx={{
                height: "auto",
                flexGrow: "1",
                overflow: "hidden",
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <LeftPanel />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "80%",
                  marginLeft: "8px",
                }}
              >
                <NavigationComponent />
                <Container
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    maxWidth: "100% !important",
                    backgroundColor: theme.background.page,
                    marginTop: "8px",
                    height: "82vh",
                    overflow: "auto",
                  }}
                >
                  {childComponent}
                </Container>
              </Box>
            </Box>
          </Box>
        </EquipmentProvider>
      </SnackbarProvider>
    );
  }

  return <Login />;
}
