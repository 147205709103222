import { ReactElement } from "react";
// import { PermissionModule } from 'shared/models/user.model';
// import { useGetUser } from 'shared/services/user.service';
//import { Root } from "../routes/root/root.route";
// import { UnableToLoadRoute } from '../routes/root/unable.to.load.route';
// import { userHasPermission } from 'shared/utils/permissions-utils';

export interface RestrictedRouterProps {
  // requiredModules: PermissionModule[];
  childComponent: ReactElement;
}

// export const UnableToLoadRouteWithRoot = () => {
//   return <Root childComponent={<UnableToLoadRoute />} />;
// };

export const RestrictedRouter = (props: RestrictedRouterProps) => {
  // const { data } = useGetUser();
  // const permissionsRequired = props.requiredModules;
  // const userPermissions = data?.permissions;

  // if (userHasPermission(permissionsRequired, userPermissions))
  return <>{props.childComponent}</>;

  // return <UnableToLoadRouteWithRoot />;
};
