import React, { useState } from "react";
import { Box } from "@mui/material";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const CartsGraphView = () => {
  const [interval, setInterval] = useState("1M");

  const generateWaveData = (length: any, baseValue: any, maxVariation: any) => {
    let currentValue = baseValue;
    return Array.from({ length }, () => {
      const variation = Math.sin(Math.random() * maxVariation) * maxVariation;
      currentValue = Math.max(0, currentValue + variation);
      return Math.round(currentValue);
    });
  };

  const generateData = () => {
    const labels =
      interval === "1M"
        ? ["Week 1", "Week 2", "Week 3", "Week 4"]
        : ["Month 1", "Month 2", "Month 3"];
    const length = labels.length;
    return {
      labels,
      datasets: [
        {
          label: "Dataset 1",
          data: generateWaveData(
            length,
            interval === "1M" ? 20 : 60,
            interval === "1M" ? 10 : 20
          ),
          borderColor: "rgba(75,192,192,1)",
          backgroundColor: "rgba(75,192,192,0.4)",
          borderWidth: 1,
          tension: 0.4,
          fill: true,
        },
        {
          label: "Dataset 2",
          data: generateWaveData(
            length,
            interval === "1M" ? 15 : 50,
            interval === "1M" ? 8 : 15
          ),
          borderColor: "rgba(153,102,255,1)",
          backgroundColor: "rgba(153,102,255,0.4)",
          borderWidth: 1,
          tension: 0.4,
          fill: true,
        },
      ],
    };
  };

  const data = generateData();

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        ticks: { color: "#bbb" },
      },
      x: {
        ticks: { color: "#bbb" },
      },
    },
    plugins: {
      legend: {
        display: true,
        labels: {
          color: "#bbb",
        },
      },
    },
  };

  return (
    <Box sx={{ width: "100%", height: "50%" }}>
      <Line data={data} options={options} />
    </Box>
  );
};

export default CartsGraphView;
