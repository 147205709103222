import React, { useState } from "react";
import { IconButton, InputAdornment, Stack } from "@mui/material";
import { StyledTextFieldTransparent } from "../styled/textfield/styledTextFieldTransparent.component";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";

interface SearchEquipmentProps {
  onSortOrder: () => void;
  onSearch: (query: string) => void;
  SearchEquipmentByName: (name: string) => Promise<void>;
}

const SearchEquipments: React.FC<SearchEquipmentProps> = ({
  onSortOrder,
  onSearch,
}) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState<string>("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchValue(value);
    onSearch(value);
  };

  const handleClear = () => {
    setSearchValue("");
    onSearch("");
  };

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        backgroundColor: "#eef2f5",
        padding: "5px",
      }}
    >
      <StyledTextFieldTransparent
        id="searchCustomer"
        name="searchCustomer"
        placeholder={t("component.leftpanel.search_customer")}
        value={searchValue}
        onChange={handleChange}
        fullWidth
        sx={{
          "& .MuiOutlinedInput-root": {
            border: "none",
            "& fieldset": {
              border: "none",
            },
            "&:hover fieldset": {
              border: "none",
            },
            "&.Mui-focused fieldset": {
              border: "none",
            },
            input: {
              color: "#4a4a4a",
            },
          },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={searchValue ? handleClear : undefined}>
                {searchValue ? (
                  <ClearIcon
                    sx={{
                      color: "#d32f2f",
                      transition: "color 0.3s",
                      "&:hover": { color: "#b71c1c" },
                    }}
                  />
                ) : (
                  <SearchIcon
                    sx={{
                      color: "#1976d2",
                      transition: "color 0.3s",
                      "&:hover": { color: "#115293" },
                    }}
                  />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <IconButton
        onClick={onSortOrder}
        sx={{
          color: "#1976d2",
          backgroundColor: "#e3f2fd",
          padding: "8px",
          transition: "background-color 0.3s, color 0.3s",
          "&:hover": {
            backgroundColor: "#bbdefb",
            color: "#115293",
          },
        }}
      >
        <UnfoldMoreIcon />
      </IconButton>
    </Stack>
  );
};

export default SearchEquipments;
