import * as React from "react";
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
  Skeleton,
  Grid2,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { IDownloadExport } from "../../../models/cart/actions/exportCart.model";
import { StyledBox } from "../../styled/box/styledBox.component";

interface CartDownloadsProps {
  data: IDownloadExport[];
  loading: boolean;
  noDataFound: boolean;
}

export const ExportedCartsDownloadsView: React.FC<CartDownloadsProps> = ({
  data,
  loading,
  noDataFound,
}) => {
  return (
    <Grid2>
      <Box sx={{ width: "100%", height: "100%" }}>
        <StyledBox sx={{ width: "100%", height: "100%", padding: "5px" }}>
          {loading ? (
            <Box sx={{ paddingTop: "10px", pointerEvents: "none" }}>
              <List>
                {Array.from({ length: 5 }).map((_, index) => (
                  <ListItem key={index} sx={{ mb: 1 }}>
                    <Skeleton variant="text" width="80%" height={40} />
                    <Skeleton
                      variant="rectangular"
                      width={40}
                      height={20}
                      sx={{
                        ml: 5,
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          ) : noDataFound ? (
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ textAlign: "center" }}
            >
              No download found.
            </Typography>
          ) : (
            <List>
              {data.map((item) => (
                <ListItem
                  key={item.id}
                  secondaryAction={
                    <Tooltip title="Download" arrow>
                      <span>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          href={item.url}
                          aria-label={`Download ${item.fileName}`}
                          sx={{ minWidth: "35px" }}
                        >
                          <DownloadIcon fontSize="small" />
                        </Button>
                      </span>
                    </Tooltip>
                  }
                >
                  <ListItemText
                    primary={item.cartName}
                    secondary={
                      <Stack spacing={0}>
                        <Typography variant="body2" sx={{ fontSize: "12px" }}>
                          Exported at: <b>{item.createdAt}</b>
                        </Typography>
                      </Stack>
                    }
                  />
                </ListItem>
              ))}
            </List>
          )}
        </StyledBox>
      </Box>
    </Grid2>
  );
};
