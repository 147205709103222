import React from "react";
import ReactDOM from "react-dom/client";
import { msalInstance } from "./auth";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./styles/theme";
import { RouterProvider } from "react-router-dom";
import { router } from "./router/router";
import { MsalProvider } from "@azure/msal-react";
import { ContextWrapper } from "./shared/components/wrrappers/contextWrapper.component";
import "./localization/i18n";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <ThemeProvider theme={theme}>
        <ContextWrapper>
          <RouterProvider router={router} />
        </ContextWrapper>
      </ThemeProvider>
    </MsalProvider>
  </React.StrictMode>
);
