import { useMsal } from "@azure/msal-react";
import { IconButton, useTheme } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";

export const SignOutButton = () => {
  const { instance } = useMsal();
  const theme = useTheme();

  const handleLogout = () => {
    instance.logoutRedirect();
  };

  return (
    <IconButton
      size="large"
      aria-label="Logout"
      onClick={handleLogout}
      color="inherit"
    >
      <LogoutIcon sx={{ color: theme.palette.text.primary }} />
    </IconButton>
  );
};
