import {
  AccountInfo,
  EventType,
  PublicClientApplication,
} from "@azure/msal-browser";
import { msalConfig } from "./msalConfig";

export const msalInstance = new PublicClientApplication(msalConfig);

const initializeMsal = async () => {
  try {
    await msalInstance.initialize();

    const accounts = msalInstance.getAllAccounts();
    if (!msalInstance.getActiveAccount() && accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);
    }

    msalInstance.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        msalInstance.setActiveAccount(event.payload as AccountInfo);
      }
    });

    await msalInstance.handleRedirectPromise();
  } catch (error) {
    console.error("MSAL initialization failed:", error);
  }
};

initializeMsal();
