const base = 8;

export const typography = {
  fontFamilies: {
    roboto: '"Roboto", "Helvetica", "Arial", sans-serif',
    condensed: 'Roboto Condensed, "Roboto", "Helvetica", "Arial", sans-serif',
  },
  lineHeights: {
    "0": "116.7%",
    "1": "40px",
    "2": "32px",
    "3": "24px",
    "4": "12px",
    "5": "20px",
    "6": "150%",
    "7": "143%",
    "8": "72px",
    "9": "266%",
    "10": "166%",
    "11": "26px",
    "12": "22px",
    "13": "18px",
    "14": "14px",
    "15": "36px",
    "16": "18px",
    "17": "14px",
    "18": "48px",
  },
  fontWeights: {
    light: "light",
    regular: "normal",
    medium: 500,
    "roboto-0": "light",
    "roboto-1": "light",
    "roboto-2": "normal",
    "roboto-3": "normal",
    "roboto-4": "bold",
  },
  fontSize: {
    "0": "10px",
    "1": "12px",
    "2": "13px",
    "3": "14px",
    "4": "15px",
    "5": "16px",
    "6": "20px",
    "7": "24px",
    "8": "28px",
    "9": "32px",
    "10": "36px",
    "11": "72px",
    "12": "18px",
  },
  letterSpacing: {
    "0": "-1.5px",
    "1": "-0.5px",
    "2": "0",
    "3": "0.25px",
    "4": "0.15px",
    "5": "0.17px",
    "6": "0.1px",
    "7": "1px",
    "8": "0.4px",
    "9": "0.1%",
    "10": "0%",
    "11": "0.14px",
    "12": "0.46px",
    "13": "0.16px",
  },
  paragraphSpacing: {
    "0": "0",
    none: "0",
  },
  shape: {
    borderRadius: "4",
  },
  spacing: {
    "1": `${base}`,
    "2": `${base * 2}`,
    "3": `${base * 3}`,
    "4": `${base * 4}`,
    "5": `${base * 5}`,
    "6": `${base * 6}`,
    "7": `${base * 7}`,
    "8": `${base * 8}`,
    "9": `${base * 9}`,
    "10": `${base * 10}`,
    "11": `${base * 11}`,
    "12": `${base * 12}`,
  },
};
